
import {
  defineComponent
} from 'vue'
import {
  JobBankRouteNames
} from '@/router/route-names'

export default defineComponent({
  props: {
    formData: {
      type: Object,
      default: null
    },
    isEditing: {
      type: Boolean
    },
    jobOfferCardCount: {
      type: Object,
      default: null
    }
  },
  setup (props, {
    emit
  }) {
    return {
      JobBankRouteNames
    }
  }
})
